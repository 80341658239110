<template>
  <ul>
    <li
      v-for="search in searches"
      :key="search.id"
      class="border-b border-gray-cloud last:border-b-0 hover:bg-gray-cloud hover:bg-opacity-50"
    >
      <router-link
        :to="{ name: 'ProjectSearch', params: { searchId: search.id } }"
        class="flex flex-row items-center h-12"
      >
        <div class="order-last flex-shrink-0 w-20 pl-2 sm:order-first sm:pl-0">
          <Tag :color="search.active ? 'success' : 'danger'" size="s">
            {{ search.active ? "active" : "inactive" }}
          </Tag>
        </div>
        <div class="flex flex-grow flex-row text-left truncate sm:pl-2">
          <div class="hidden md:block">{{ getSearchLabel(search.id) }}</div>
          <div class="block md:hidden">
            {{ getSearchShortLabel(search.id) }}
          </div>
          <div
            v-if="interactionCountBySearchId(search.id) > 0"
            class="pl-2 text-primary-40"
          >
            <router-link
              :to="{
                name: 'ProjectInteractions',
                params: {
                  chatId: findLastInteractionBySearchId(search.id).chat_id
                }
              }"
            >
              <Icon name="bell" size="md" class="interaction-bell" />
              {{ interactionCountBySearchId(search.id) }}
            </router-link>
          </div>
        </div>

        <div class="hidden md:block">
          <a
            ref="#"
            class="text-primary-40 pr-2"
            v-on:click.prevent="
              showModal =
                search.active || (!search.active && canActivateSearch)
                  ? `search-active-modal.${search.id}`
                  : `cannot-search-active-modal.${search.id}`
            "
            >{{
              search.active
                ? $t("pages.search.disable_action")
                : $t("pages.search.enable_action")
            }}</a
          >
        </div>
        <div v-if="search.active" class="hidden lg:block">
          <span class="text-gray-elephant"> / </span>
          <router-link
            :to="{
              name: 'ProjectSearch',
              params: { searchId: search.id, action: 'edit' }
            }"
          >
            <a class="pr-2 text-primary-40">{{ $t("pages.search.edit") }}</a>
          </router-link>
        </div>
      </router-link>

      <Snackbar v-model="showSnackbar" v-bind="snackbarProps"></Snackbar>
      <Snackbar
        v-model="showLimitSnackbar"
        v-bind="limitSnackbarProps"
      ></Snackbar>

      <Modal
        :id="`search-active-modal.${search.id}`"
        v-show="showModal == `search-active-modal.${search.id}`"
        :type="!search.active ? 'success' : 'danger'"
        :title="
          !search.active
            ? $t('pages.search.enable_title')
            : $t('pages.search.disable_title')
        "
        :actions="{
          cancelVisible: true,
          cancelLabel: 'Annuler',
          actionVisible: true,
          actionLabel: !search.active
            ? $t('pages.search.enable_action')
            : $t('pages.search.disable_action'),
          backdropClickEnabled: false
        }"
        @action="update(search)"
        @cancel="showModal = ''"
      >
        {{
          !search.active
            ? $t("pages.search.enable_label")
            : $t("pages.search.disable_label")
        }}
      </Modal>

      <Modal
        :id="`cannot-search-active-modal.${search.id}`"
        v-show="showModal == `cannot-search-active-modal.${search.id}`"
        type="danger"
        :title="$t('pages.search.cannot_enable_title')"
        :actions="{
          cancelVisible: false,
          cancelLabel: '',
          actionVisible: true,
          actionLabel: $t('pages.search.cannot_enable_action'),
          backdropClickEnabled: false
        }"
        @action="showModal = ''"
      >
        {{ $t("pages.search.cannot_enable_label") }}
      </Modal>
    </li>
  </ul>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "SearchList",

  data: function() {
    return {
      showSnackbar: false,
      showLimitSnackbar: false,
      showModal: "",
      hasError: false
    };
  },

  props: {
    searches: {
      type: Array
    }
  },

  computed: {
    ...mapGetters("search", [
      "getSearchLabel",
      "getSearchShortLabel",
      "canActivateSearch",
      "hasNewInactiveSearch"
    ]),
    ...mapGetters("interaction", [
      "interactionCountBySearchId",
      "findLastInteractionBySearchId"
    ]),
    ...mapGetters("user", ["gtmProfile"]),

    snackbarProps() {
      let props = {
        variant: "success",
        text: "Votre recherche a été mise à jour !",
        action: "OK",
        x: "center",
        y: "top",
        timeout: 5
      };

      if (this.hasError) {
        props = {
          ...props,
          variant: "error",
          text: "Erreur ! Impossible de modifier votre recherche"
        };
      }

      return props;
    },

    limitSnackbarProps() {
      return {
        variant: "error",
        text:
          "Votre dernière recherche est désactivée (limite de 3 recherches actives atteinte).",
        action: "OK",
        x: "center",
        y: "top",
        timeout: 5
      };
    }
  },

  methods: {
    ...mapActions("search", {
      updateSearch: "update"
    }),

    async update(search) {
      this.showModal = false;
      search.active = !search.active;
      this.updateSearch({
        id: search.id,
        payload: search
      })
        .catch(() => {
          this.hasError = true;
          this.showSnackbar = true;
          search.active = !search.active;
        })
        .finally(() => {
          this.hasError = false;
          this.showSnackbar = true;

          this.$gtm.trackEvent({
            event: search.active
              ? "user-estate-search-edit"
              : "user-estate-search-disable",
            eventCategory: "Estate Search",
            eventAction: search.active
              ? "Estate Search Edit"
              : "Estate Search Disable",
            eventLabel: this.gtmProfile,
            eventValue: "",
            noninteraction: false
          });
        });
    }
  },

  mounted() {
    if (this.hasNewInactiveSearch && !this.canActivateSearch) {
      this.showLimitSnackbar = true;
    }
  }
};
</script>

<style class="postcss" scoped>
.interaction-bell {
  margin-top: -4px;
}
</style>
