<template>
  <div v-if="!loading">
    <header class="flex flex-col">
      <div class="hidden lg:flex flex-row items-center">
        <h1 class="mr-4">{{ $t("pages.searches.title") | capitalize }}</h1>
      </div>
    </header>
    <div>
      <SearchList :searches="searchArray" />
    </div>
  </div>
  <div v-else>
    <Loading />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import _ from "lodash";

import SearchList from "@/components/search/SearchList.vue";

export default {
  components: {
    SearchList
  },

  data: function () {
    return {
      loading: true
    };
  },

  computed: {
    ...mapState("search", ["searchArray"]),
    ...mapState("project", ["selectedProject"])
  },

  methods: {
    ...mapActions("project", {
      fetchProjectSearches: "fetchSearches"
    })
  },

  async mounted() {
    try {
      if (this.selectedProject && _.isEmpty(this.searchArray)) {
        await this.fetchProjectSearches(this.selectedProject.id);
      }
    } finally {
      this.loading = false;
    }
  }
};
</script>
